import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-layout-new-user',
  standalone: true,
  imports: [CommonModule, MatToolbarModule, RouterLink, MatIconModule, TranslateModule, RouterOutlet],
  templateUrl: './layout-new-user.component.html',
  styleUrl: './layout-new-user.component.scss'
})
export class LayoutNewUserComponent {
  constructor( 
    ) { 

    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
    }
}
