<div class="jk-form-container jk-single-column-form">
    <!-- <mat-card class="jk-sr-card">
      <mat-card-header>
  
        <mat-card-title>{{user.first_name}} {{user.last_name}}</mat-card-title> 
        <mat-card-title>{{'LOGIN' | translate }} </mat-card-title>
  
      </mat-card-header> -->
      <h1>{{'LOGIN' | translate }}</h1>
      <form [formGroup]="formLoginGroup" autocomplete="off">
  
        <div class="jk-form-field-container">
          <mat-form-field>
            <mat-label>{{ 'USER.EMAIL' | translate }}</mat-label>
            <input type="text" matInput formControlName="email" placeholder="">
            @if (formLoginGroup.controls['email'].errors?.['required']) {
              <mat-error> Email ID is required</mat-error>
              }
              @if (formLoginGroup.controls['email'].errors?.['email']) {
                <mat-error>Please enter a valid email address</mat-error>
                }
          </mat-form-field>
  
        </div>
        <div class="jk-form-field-container">
          <mat-form-field>
            <mat-label>{{ 'USER.PASSWORD' | translate }}</mat-label>
            <input type="password" matInput formControlName="password" placeholder="">
            @if (formLoginGroup.controls['password'].errors?.['required']) {
              <mat-error>Password is required</mat-error>
              }
              @if (formLoginGroup.controls['password'].errors?.['minlength']) {
                <mat-error>Password length should be 8 characters</mat-error>
                }
          </mat-form-field>
        </div>
  
        <div class="jk-form-field-container">
          <button mat-button mat-raised-button color="primary" type="submit" (click)="saveLogin()"
            [disabled]="!formLoginGroup.valid">{{
            'LOGIN' | translate }}
            <app-spinner *ngIf="showSpinner"></app-spinner>
          </button>
        </div>
      </form>
  
      <!-- <div class="jk-ln-links">
        <a class="jk-ln-otp" [routerLink]="'/new-user/login/otp'">Login with OTP</a>
        <a class="jk-ln-fp" [routerLink]="'/new-user/login/otp'">Forgot Password?</a>
        <a class="jk-ln-sp" [routerLink]="'/new-user/signup'">New User? Signup</a>
      </div> -->
  
    <!-- </mat-card> -->
  </div>