import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AdminService } from '../../shared/services/admin.service';
import { BrowserStorageService } from '../../shared/services/storage.service';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [CommonModule, RouterLink, SpinnerComponent, ReactiveFormsModule, MatInputModule, MatFormFieldModule, MatCardModule, MatButtonModule, TranslateModule],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {

    formLoginGroup!: FormGroup;
    public showSpinner: boolean = false;
    constructor(
        private readonly fb: FormBuilder,
        private adminService: AdminService,
        private browserStorageService: BrowserStorageService,
        //private authService: AuthService,
        // public dialog: MatDialog,
        // private readonly translate: TranslateService,
        private router: Router
    ) {

    }

    ngOnInit() {
        this.setFormGroup();
    }

    setFormGroup() {

        this.formLoginGroup = this.fb.group({

            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(8)]],

        });
    }

    saveLogin() {
        this.showSpinner = true;

        const userLoginDetailsJson = {
            email: this.formLoginGroup.get('email')!.value,
            password: this.formLoginGroup.get('password')!.value,
        };

        // Check if one of the fields in the form group has been modified
        if (this.formLoginGroup.dirty && this.formLoginGroup.valid) {

            // Insert User DETAILS
            this.adminService.loginUser(userLoginDetailsJson).subscribe(response => {

                // Remove all the other user details set by previous login if any
                this.browserStorageService.clear();

                // Save authorize token in local storage                
                this.browserStorageService.set('token', response.data.token);
                this.setUserDetails(response.data);
                this.showSpinner = false;
                this.router.navigate(['/admin/property/list']);
                
            });


        }
    }

    setUserDetails(user: any) {

        const name = user.first_name + ' ' + user.last_name;
        // The name comes from user table.  
        this.browserStorageService.set('displayName', name);
        if (user.is_backend_user) {
            this.browserStorageService.set('beu', '1');
            //this.router.navigate(['/admin']);
        } else {
            this.browserStorageService.set('uid', user.user_id);
        }

    }

}
