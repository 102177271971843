<div class="jk-app mat-typography">
    <mat-toolbar color="primary">
        <mat-toolbar-row>
            <span>{{'APP_TITLE' | translate}}</span>
            <span class="jk-spacer"></span>
            <button mat-icon-button>
                <mat-icon>menu</mat-icon>
            </button>
        </mat-toolbar-row>

        <mat-toolbar-row>
            <span class="jk-spacer"></span>
            <a [routerLink]="['/new-user/signup']">
                <mat-icon color="secondary">person_add</mat-icon>
                {{'SIGNUP' | translate }}
            </a>

            <a [routerLink]="['/new-user/login']">
                <mat-icon>login</mat-icon>
                {{'LOGIN' | translate }}
            </a>
            
        </mat-toolbar-row>
    </mat-toolbar>

    
    <div class="jk-layout-body-container">
        <router-outlet></router-outlet>
    </div>

    <footer class="jk-lp-footer" color="primary">
        <ul>
            <li><a [routerLink]="['/new-user/about-us']">About Us</a></li>
            <li><a [routerLink]="['/new-user/privacy-policy']">Privacy Policy</a></li>
            <li><a [routerLink]="['/new-user/terms-conditions']">Terms & Conditions</a></li>
            <li><a [routerLink]="['/new-user/faqs']">FAQs</a></li>
            <li><a [routerLink]="['/new-user/contact-us']">Contact Us</a></li>
        </ul>
        <div class="jk-lp-cr">&copy; 2024 CourtallamRooms.com, All rights reserved.</div>
    </footer>
</div>