import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../shared/services/admin.service';
import { BrowserStorageService } from '../../shared/services/storage.service';

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent implements OnInit{
  displayLogoutSuccessMsg: boolean = false;
  displayLogoutAllSuccessMsg: boolean = false;
  constructor(
    public adminService: AdminService, 
    public browserStorageService: BrowserStorageService
    ) { }

  ngOnInit(): void {
    this.logout();
  }

  logout(){
    this.adminService.logoutUser().subscribe(Response => {
      this.browserStorageService.clear();
      this.displayLogoutSuccessMsg = true;
    })
  }

  // logoutFromAllDevices(){
  //   this.userService.logoutUserFromAllDevices().subscribe(Response => {
  //     this.displayLogoutAllSuccessMsg = true;
  //   })
  // }

}
