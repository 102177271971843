import { Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { LayoutNewUserComponent } from './core/layout-new-user/layout-new-user.component';
// import { OwnerDetailsComponent } from './property/owner-details/owner-details.component';
// import { PrimaryManagerDetailsComponent } from './property/primary-manager-details/primary-manager-details.component';
// import { PoliciesComponent } from './property/policies/policies.component';
// import { RoomsComponent } from './property/rooms/rooms.component';

export const routes: Routes = [

    { path: '', redirectTo: 'new-user/login', pathMatch: 'prefix' },
    {
        path: 'new-user', component: LayoutNewUserComponent,
        children: [
            { path: 'login', component: LoginComponent },
            /* :userId is for the new users who were added by the admin  */
            // { path: 'login/otp/:userId', component: LoginOtpComponent },
            // { path: 'verify/:userId', component: LoginOtpComponent },

            // /* This will take user to verification form as the otp would be sent from another page */
            // { path: 'login/otp/verify/:verificationId', component: LoginOtpComponent },

            { path: 'logout', component: LogoutComponent },
        ]


    },
    {path: 'admin', loadChildren: () => import('./admin.routes').then(mod => mod.ADMIN_ROUTES)},
    
];
