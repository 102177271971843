import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterLink, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RoomsComponent } from "./property/rooms/rooms.component";
import { MasterDataService } from './shared/services/master-data.service';




@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [CommonModule, RouterOutlet, RoomsComponent, TranslateModule, MatSidenavModule, RouterLink,]
})
export class AppComponent {

  // @ViewChild('rightSideNav', { static: true }) private rightSideNav!: MatSidenav;
  // @ViewChild('rightSideNavContainer', { static: true, read: ViewContainerRef }) private containerRef!: ViewContainerRef;

  constructor(
    private masterDataService: MasterDataService
  ) {
    this.masterDataService.loadMasterData();

  }
  // ngOnInit(): void {

  //   this.sideNavService.setNavRef(this.rightSideNav);
  //   this.sideNavService.setContainerRef(this.containerRef);
  // }
}



